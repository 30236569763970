export const setValue = (e, state, setState) => {
  const n = e.target.name;
  const v = e.target.value;
  setState({ ...state, [n]: v });
};

export const setModelValue = (e, state, setState) => {
  const n = e.target.name;
  const v = e.target.value;
  setState({ ...state, model: { ...state.model, [n]: v } });
};

export const setErrorValue = (state, setState, n, errVal) => {
  setState({ ...state, error: { ...state.error, [n]: errVal } });
};

export const errorMessage = (error, type, message) => {
  if (type) {
    if (error?.type === type) {
      return <span className="text-danger">{message}</span>;
    }
  } else {
    switch (error?.type) {
      case "required":
        return <span className="text-danger">Required field</span>;
      case "maxLength":
        return (
          <span className="text-danger">
            Maximum characters are {error?.ref.maxLength}
          </span>
        );
      case "min":
        return (
          <span className="text-danger">
            Minimum number is {error?.ref.min}
          </span>
        );
      case "max":
        return (
          <span className="text-danger">
            Maximum number is {error?.ref.max}
          </span>
        );
    }
  }
};

export const waitButton = (isSubmitting) => {
  if (isSubmitting) {
    return <span className="spinner-border spinner-grow-sm"></span>;
  }
};
