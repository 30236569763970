import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/shared/Layout";
import "./styles/shared/main.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrentUserContextProvider from "./contexts/CurrentUserContext";
import Home from "./components/pages/Home";
import WebLogin from "./components/pages/player/WebLogin";
import WebSignup from "./components/pages/player/WebSignup";
import Login from "./components/pages/admin/Login";
import Game from "./components/pages/admin/Game";
import Category from "./components/pages/admin/Category";
import CurrentPlayerContextProvider from "./contexts/CurrentPlayerContext";
import Account from "./components/pages/player/Account";
import PlayerLayout from "./components/shared/PlayerLayout";
import Score from "./components/pages/admin/Score";
import WebResetPassword from "./components/pages/player/WebResetPassword";
import WebResetPasswordLevel2 from "./components/pages/player/WebResetPasswordLevel2";

function App() {
  return (
    <div className="App">
      <CurrentUserContextProvider>
        <CurrentPlayerContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Home />} />

              <Route path="/player/weblogin" element={<WebLogin />} />
              <Route path="/player/websignup" element={<WebSignup />} />
              <Route path="/player/webresetpassword" element={<WebResetPassword />} />
              <Route path="/player/webresetpasswordlevel2" element={<WebResetPasswordLevel2 />} />

              <Route element={<PlayerLayout />}>
                <Route path="/player/account" element={<Account />} />
              </Route>

              <Route path="/admin/login" element={<Login />} />

              <Route element={<Layout />}>
                <Route path="/admin/category" element={<Category />} />
                <Route path="/admin/game" element={<Game />} />
                <Route path="/admin/score" element={<Score />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </CurrentPlayerContextProvider>
      </CurrentUserContextProvider>
    </div>
  );
}

export default App;
