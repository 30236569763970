import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import config from "../../../helpers/config";
import CategorySelect from "../../shared/CategorySelect";
import DisabledFormElement from "../../shared/DisabledFormElement";
import HideByPlatformFormElement from "../../shared/HideByPlatformFormElement";
import NameFormElement from "../../shared/NameFormElement";
import SaveButton from "../../shared/SaveButton";
import SubContainer from "../../shared/SubContainer";
import SubHeader from "../../shared/SubHeader";
import WordSaveAndList from "./WordSaveAndList";

const GameSave = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: props.game });

  const onSubmit = (data) => {
    axios
      .post(config.callUrl + "/game/save", data)
      .then((response) => {
        props.changePageMode("list");
        toast.success("Success");
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };

  const resetModel = () => {
    reset(props.game);
  };

  return (
    <SubContainer>
      <SubHeader
        title="Save Game"
        linkTitle="Game List"
        willChangePageMode="list"
        changePageMode={props.changePageMode}
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3">
          <div className="col-6">
            <CategorySelect
              register={register}
              errors={errors}
              reset={resetModel}
              categoryEnum={props.enums.categoryEnum}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <NameFormElement
              register={register}
              errors={errors}
              maxLength="250"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <DisabledFormElement register={register} />
          </div>
        </div>

        
        <div className="row mb-3">
          <div className="col">
            <HideByPlatformFormElement register={register} item='hideWeb' itemName='Hide on Web' />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <HideByPlatformFormElement register={register} item='hideMobile' itemName='Hide on Mobile' />
          </div>
        </div>

        <div className="row">
          <div className="col">
           <SaveButton isSubmitting={isSubmitting}/>
          </div>
        </div>
      </form>

      <WordSaveAndList gameId={props.game?._id} />
    </SubContainer>
  );
};

export default GameSave;
