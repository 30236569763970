import { useEffect, useState } from "react";

function Pager({ count, changed }) {
  const [totalPage, setTotalPage] = useState([1]);
  const [pager, setPager] = useState({ page: 1, size: 20 });

  useEffect(() => {
    if (count && pager.size) {
      const pageArray = [];
      const totalPage = Math.ceil(count / pager.size);
      for (let i = 1; i <= totalPage; i++) {
        pageArray.push(i);
      }
      setTotalPage(pageArray);
      //   renderOptions();
    }
  }, [count, pager.size]);

  useEffect(() => {
    if (changed) {
      changed(pager);
    }
  }, [pager]);

  const pChanged = (e) => {
    setPager({ ...pager, page: parseInt(e.target.value) });
  };

  const sChanged = (e) => {
    setPager({ page: 1, size: parseInt(e.target.value) });
  };

  return (
    <div className="row my-2 justify-content-end align-items-center">
      <div className="col-auto text-end ">Size</div>
      <div className="col-auto">
        <select
          className="form-select"
          defaultValue={pager.size}
          onChange={sChanged}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </select>
      </div>

      <div className="col-auto text-end ">Page</div>
      <div className="col-auto">
        <select
          className="form-select"
          defaultValue={pager.page}
          onChange={pChanged}
        >
          {totalPage.map((item, i) => {
            return (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default Pager;
