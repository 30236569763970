function EditDeleteButton(props) {
  const onClick = (e,type) => {
    e.preventDefault();
    props.onClick(type, props.id);
  };

  return (
    <div>
      <a
        href=""
        className="btn btn-outline-primary btn-sm me-2"
        title="Edit"
        onClick={(e)=>{
            onClick(e,"edit");
        }}
      >
        <i className="bi bi-pencil-square"></i>
      </a>

      <a
        href=""
        className="btn btn-danger btn-sm"
        title="Delete"
        onClick={(e)=>{
            onClick(e,"del");
        }}
      >
        <i className="bi bi-trash3"></i>
      </a>
    </div>
  );
}

export default EditDeleteButton;
