import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import config from "../../../helpers/config";

export default function CertModal({ pg }) {
  const [playerGame, setPlayerGame] = useState(pg);
  useEffect(() => {
    setPlayerGame(pg);
  }, [pg]);

  const certChanged = (e) => {
    setPlayerGame({ ...playerGame, cert: e.target.checked });
  };

  const placementChanged = (e) => {
    setPlayerGame({ ...playerGame, placement: e.target.value });
  };

  const saveCert = () => {
    axios
      .post(config.callUrl + "/game/updateCertInfo", {
        _id: playerGame._id,
        cert: playerGame.cert,
        placement: playerGame.placement,
      })
      .then(() => {
        toast.success("Success");
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };

  return (
    <div
      className="modal fade"
      id="certModal"
      tabindex="-1"
      aria-labelledby="certModalLbl"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="certModalLbl">
              Certificate
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Show certificate to student: </label>
              </div>
              <div className="col-md-6 form-group">
                <input
                  type="checkbox"
                  checked={playerGame?.cert || false}
                  onChange={certChanged}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="">Placement: </label>
              </div>
              <div className="col-md-6 form-group">
                <input
                  type="text"
                  className="form-control"
                  value={playerGame?.placement || ""}
                  onChange={placementChanged}
                ></input>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveCert}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
