import React from "react";
import { errorMessage } from "../../helpers/form-helper";

const CategorySelect = ({ register, errors ,categoryEnum}) => {

  return (
    <div>
      <label className="form-label" htmlFor="name">
        Category
      </label>
      <select
        className="form-select"
        {...register("category", { required: true })}
      >
        <option value=""></option>
        {categoryEnum?.map((c) => {
          return (
            <option key={c._id} value={c._id}>
              {c.name}
            </option>
          );
        })}
      </select>
      {errorMessage(errors.category)}
    </div>
  );
};

export default CategorySelect;
