import React from "react";
import PageTitle from "../../shared/PageTitle";
import RequireAuth from "../../shared/RequireAuth";
import ScoreList from "./ScoreList";

const Score = () => {
  return (
    <RequireAuth validate="/game">
      <div>
        <PageTitle title="Scores" />
        <ScoreList />
      </div>
    </RequireAuth>
  );
};

export default Score;
