function SubHeader(props) {
  const changePageMode = (e, mode) => {
    e.preventDefault();
    props.changePageMode(props.willChangePageMode);
  };

  return (
    <div>
      <div className="row justify-content-between">
        <div className="col-auto">
          <h6>{props.title}</h6>
        </div>
        <div className="col-auto ">
          {props.linkTitle && (
            <a
              href=""
              onClick={changePageMode}
              className="btn btn-outline-primary"
            >
              {props.linkTitle}
            </a>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}

export default SubHeader;
