import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CurrentPlayerContext } from "../../contexts/CurrentPlayerContext";
import PlayerHeader from "./PlayerHeader";

const PlayerLayout = () => {
  const nav = useNavigate();
  const { player, changeCurrentPlayer } = useContext(CurrentPlayerContext);


  return (
    <div className="">
      <PlayerHeader />
      <div className="p-4">
        <Outlet />
      </div>
    </div>
  );
};

export default PlayerLayout;
