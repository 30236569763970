import React, { createRef, useContext, useEffect, useState } from "react";
import axios from "axios";
// import TempNav from "../../shared/TempNav";
import "../../../styles/pages/login.css";
import { useForm } from "react-hook-form";
import config from "../../../helpers/config";
import { errorMessage, waitButton } from "../../../helpers/form-helper";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import qs from "query-string";
import { useNavigate } from "react-router-dom";
import { CurrentPlayerContext } from "../../../contexts/CurrentPlayerContext";

export default function WebLogin(props) {
  const { player, changeCurrentPlayer } = useContext(CurrentPlayerContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({});
  const rcref = createRef();
  const [rctoken, changeRCtoken] = useState("");
  const nav = useNavigate();

  // useEffect(() => {
  //   console.log("player web login effect");
  //   if (player) {
  //     nav("/player/account");
  //   }
  // }, [player]);

  useEffect(() => {
    console.log("player web login effect []");
    if (player) {
      axios
        .get(config.callUrl + "/playerweb/logout")
        .then(() => {
          changeCurrentPlayer(null);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.response.data);
        });
    }
  }, []);

  const onSubmit = (data) => {
    if (!rctoken) {
      return;
    }
    const queryParams = qs.parse(window.location.search);
    console.log("querystring: " + JSON.stringify(queryParams));
    axios
      .post(
        config.callUrl +
          "/playerweb/loginwc?rctoken=" +
          rctoken +
          "&nr=" +
          queryParams?.nr,
        data
      )
      .then((response) => {
        console.log(response);
        if (queryParams?.nr) {
          toast.success("Giriş başarılı.");
          changeCurrentPlayer(response.data);
          nav("/player/account");
        } else {
          toast.success(
            "Giriş başarılı. Oyuna yönlendiriliyorsunuz. Lütfen bekleyiniz..."
          );
          setTimeout(() => {
            window.location.href = config.gameUrl + "?p=" + response.data;
          }, 2000);
        }
      })
      .catch((error) => {
        rcchange(null);
        window.grecaptcha.reset();
        console.error(error);
        toast.error(error?.response?.data);
      });
  };
  // if (errors) {
  //   console.error(errors);
  // }

  const rcchange = (rcval) => {
    console.log("rcval: " + rcval);
    changeRCtoken(rcval);
  };

  const resetPasswordClicked = (e) => {
    e.preventDefault();
    nav("/player/webresetpassword");
  };

  return (
    <div className="login-container">
      <main className="form-signin">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <img className="mb-4" src="/logo.png" alt="" height="100" />
          <h1 className="h3 mb-3 fw-normal">Giriş Bilgileriniz</h1>

          <div className="form-floating">
            <input
              type="username"
              className="form-control"
              id="fusername"
              placeholder="e-Posta"
              maxLength="50"
              {...register("username", {
                required: true,
                maxLength: 255,
                pattern: /^\S+@\S+$/i,
              })}
            />
            <label htmlFor="fusername">e-Posta</label>
            {errorMessage(errors.username, "required", "e-Posta zorunludur")}
            {errorMessage(errors.username, "pattern", "e-Posta formatı hatalı")}
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="fPassword"
              placeholder="Şifre"
              maxLength="50"
              {...register("password", { required: true, maxLength: 50 })}
            />
            <label htmlFor="fPassword">Şifre</label>
            {errorMessage(errors.password, "required", "Şifre zorunludur")}
          </div>

          <div className="form-floating">
            <ReCAPTCHA
              ref={rcref}
              sitekey={config.rcSiteKey}
              hl="tr"
              onChange={rcchange}
            />
          </div>

          <button
            disabled={isSubmitting}
            className="w-100 btn btn-lg btn-primary"
            type="submit"
          >
            {waitButton(isSubmitting)}
            {!isSubmitting && "Giriş"}
          </button>

          <div className="mt-2 text-center">
            <a href="#" className="" onClick={resetPasswordClicked}>
              Şifremi Unuttum
            </a>
          </div>

          {/* <TempNav /> */}
        </form>
      </main>
    </div>
  );
}
