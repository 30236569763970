import React from "react";
import { waitButton } from "../../helpers/form-helper";

const SaveButton = ({ isSubmitting, small }) => {
  let btnClass = "btn btn-primary";
  if (small) {
    btnClass += " btn-sm";
  }
  return (
    <button className={btnClass} disabled={isSubmitting}>
      {waitButton(isSubmitting)}
      {!isSubmitting && <i className="bi bi-save me-1"></i>}
      {!isSubmitting && "Save"}
    </button>
  );
};

export default SaveButton;
