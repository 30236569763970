import React from "react";
import PlayerRequireAuth from "../../shared/PlayerRequireAuth";
import Certs from "./Certs";
import PersonalInformations from "./PersonalInformations";

export default function Account() {
  return (
    <PlayerRequireAuth validate="/player/account">
      <div className="">
        <h3>Hesap Ayarları</h3>
      </div>
      <div className="row">
        <div className="col-md-4">
          <PersonalInformations />
        </div>
        <div className="col-md-4">{<Certs />}</div>
        <div className="col-md-4">{/* <Personalnformations /> */}</div>
      </div>
    </PlayerRequireAuth>
  );
}
