import React, { createRef, useEffect, useState } from "react";
import axios from "axios";
// import TempNav from "../../shared/TempNav";
import "../../../styles/pages/login.css";
import { useForm } from "react-hook-form";
import config from "../../../helpers/config";
import { errorMessage, waitButton } from "../../../helpers/form-helper";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import qs from "query-string";

export default function WebResetPasswordLevel2() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({});
  const rcref = createRef();
  const [rctoken, changeRCtoken] = useState("");
  const nav = useNavigate();

  const [code, setCode] = useState("");
  useEffect(() => {
    const queryParams = qs.parse(window.location.search);
    if (queryParams?.c) {
      setCode(queryParams.c);
    }
  }, []);

  const onSubmit = (data) => {
    if (!rctoken) {
      return;
    }

    axios
      .post(
        config.callUrl + "/playerweb/resetpasswordlevel2?rctoken=" + rctoken,
        { ...data, code }
      )
      .then((response) => {
        console.log(response);

        toast.success(response.data);
        nav("/player/weblogin");
      })
      .catch((error) => {
        rcchange(null);
        window.grecaptcha.reset();
        console.error(error);
        toast.error(error?.response?.data);
      });
  };
  // if (errors) {
  //   console.error(errors);
  // }

  const rcchange = (rcval) => {
    console.log("rcval: " + rcval);
    changeRCtoken(rcval);
  };

  if (code) {
    return (
      <div className="login-container">
        <main className="form-signin">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <img className="mb-4" src="/logo.png" alt="" height="100" />
            <h1 className="h3 mb-3 fw-normal">Şifremi Sıfırla</h1>

            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="fPassword"
                placeholder="Şifre"
                maxLength="50"
                {...register("password", { required: true, maxLength: 50 })}
              />
              <label htmlFor="fPassword">Şifre</label>
              {errorMessage(errors.password, "required", "Şifre zorunludur")}
            </div>

            <div className="form-floating">
              <ReCAPTCHA
                ref={rcref}
                sitekey={config.rcSiteKey}
                hl="tr"
                onChange={rcchange}
              />
            </div>

            <button
              disabled={isSubmitting}
              className="w-100 btn btn-lg btn-primary"
              type="submit"
            >
              {waitButton(isSubmitting)}
              {!isSubmitting && "Değiştir"}
            </button>
          </form>
        </main>
      </div>
    );
  } else {
    return "";
  }
}
