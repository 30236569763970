import axios from "axios";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CurrentPlayerContext } from "../../../contexts/CurrentPlayerContext";
import config from "../../../helpers/config";
import { errorMessage, waitButton } from "../../../helpers/form-helper";

export default function PersonalInformations() {
  const { player, changeCurrentPlayer } = useContext(CurrentPlayerContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: player });

  const onSubmit = (data) => {
    axios
      .post(config.callUrl + "/player/saveinfo", data)
      .then(() => {
        toast.success("Bilgileriniz kaydedildi.");
        changeCurrentPlayer(data);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Kişisel Bilgiler</h5>
        <hr />
        <div className="card-text">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="form-group mb-2">
              <label className="">Ad</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder=""
                maxLength="250"
                {...register("name", {
                  required: true,
                  maxLength: 250,
                })}
              />
              {errorMessage(errors.name, "required", "Ad zorunludur")}
            </div>

            <div className="form-group mb-2">
              <label className="">Soyad</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder=""
                maxLength="250"
                {...register("surname", {
                  required: true,
                  maxLength: 250,
                })}
              />
              {errorMessage(errors.surname, "required", "Soyad zorunludur")}
            </div>

            <div className="form-group mb-2">
              <label className="">Sınıf</label>
              <input
                type="number"
                min="2"
                max="12"
                size="1"
                className="form-control form-control-sm"
                placeholder=""
                maxLength="250"
                {...register("class", {
                  maxLength: 50,
                  min: 2,
                  max: 12,
                })}
              />
              {errorMessage(
                errors.class,
                "min",
                "Sınıf 2. sınıftan 12. sınıfa kadardır"
              )}
              {errorMessage(
                errors.class,
                "max",
                "Sınıf 2. sınıftan 12. sınıfa kadardır"
              )}
            </div>

            <div className="form-group mb-2">
              <label className="">Okul</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder=""
                maxLength="250"
                {...register("school", {
                  maxLength: 250,
                })}
              />
            </div>

            <div className="form-group mb-2">
              <label className="">İlçe</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder=""
                maxLength="250"
                {...register("town", {
                  maxLength: 250,
                })}
              />
            </div>

            <div className="form-group mb-2">
              <label className="">İl</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder=""
                maxLength="250"
                {...register("city", {
                  maxLength: 100,
                })}
              />
            </div>

            <div className="form-group mb-2">
              <label className="">Telefon</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder=""
                maxLength="250"
                {...register("phone", {
                  maxLength: 50,
                })}
              />
            </div>

            <button
              disabled={isSubmitting}
              className="btn btn-sm btn-primary mt-2"
              type="submit"
            >
              <i className="bi bi-save"> </i>
              {waitButton(isSubmitting)}
              {!isSubmitting && "Kaydet"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
