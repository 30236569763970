import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import config from "../../../helpers/config";
import { getEnumName } from "../../../helpers/enums";
import DisableCheckbox from "../../shared/DisableCheckbox";
import EditDeleteButton from "../../shared/EditDeleteButton";
import Pager from "../../shared/Pager";
import SubContainer from "../../shared/SubContainer";
import SubHeader from "../../shared/SubHeader";

const cookies = new Cookies();

const GameList = (props) => {
  const [gameList, setGameList] = useState();
  useEffect(() => {
    axios
      .get(config.callUrl + "/game/get")
      .then((response) => {
        // toast.success("Success");

        setGameList(response.data?.reverse());
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);

      });
  }, []);



  const onDisabledChanged = (id, disabled) => {
    axios
      .put(config.callUrl + "/game/changedisabled/" + id, { d: disabled })
      .then((response) => {
        toast.success("Success");
       
        let list = [...gameList];

        list.forEach((i) => {
          if (i._id === id) {
            i.disabled = disabled;
            return false;
          }
        });

        setGameList(list?.reverse());
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };

  const onClickEditDelete = (type, id) => {


    if (type === "edit") {
      const game = gameList.find((a) => {
        return a._id === id;
      });
      props.selectGame(game);
    } else if (type === "del") {
      axios
        .put(config.callUrl + "/game/delete/" + id)
        .then((response) => {
          toast.success("Success");

          const list = gameList.filter((i) => {
            return i._id !== id;
          });

          setGameList(list?.reverse());
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.response.data);
        });
    }
  };

  return (
    <SubContainer>
      <SubHeader
        title="Game List"
        linkTitle="New Game"
        willChangePageMode="save"
        changePageMode={props.changePageMode}
      />
      <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Category</th>
            <th>Disabled</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {gameList?.map((item) => {
            return (
              <tr key={item._id}>
                <td>{item._id}</td>
                <td>{item.name}</td>
                <td>
                  {getEnumName(props.enums?.categoryEnum, item.category)}
                </td>
                <td>
                  <DisableCheckbox
                    checked={item.disabled}
                    id={item._id}
                    onChange={onDisabledChanged}
                  />
                </td>
                <td className="text-end">
                  <EditDeleteButton id={item._id} onClick={onClickEditDelete} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pager />
    </SubContainer>
  );
};

export default GameList;
