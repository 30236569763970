import React, { createRef, useContext, useEffect, useState } from "react";
import axios from "axios";
// import TempNav from "../../shared/TempNav";
import "../../../styles/pages/login.css";
import { useForm } from "react-hook-form";
import config from "../../../helpers/config";
import { errorMessage, waitButton } from "../../../helpers/form-helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../../contexts/CurrentUserContext";
import Loading from "../../shared/Loading";

import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({});
  let navigate = useNavigate();
  const rcref = createRef();
  // axios.defaults.withCredentials = true;

  const { user, changeCurrentUser } = useContext(CurrentUserContext);
  const [rctoken, changeRCtoken] = useState("");

  useEffect(() => {
    console.log("login effect");
    if (user) {
      navigate("/admin/category");
    }
  }, [user]);

  const onSubmit = (data) => {
    // console.log("rctoken: " + rctoken);
    if (!rctoken) {
      return;
    }
    axios
      .post(config.callUrl + "/admin/login?rctoken=" + rctoken, data)
      .then((response) => {
        toast.success("Success");
        changeCurrentUser(response.data);
        // navigate("/admin/category");
      })
      .catch((err) => {
        toast.error(err.response.data);
        console.error(err.response.data);
      });
  };
  // if (errors) {
  //   console.error(errors);
  // }

  const rcchange = (rcval) => {
    console.log("rcval: " + rcval);
    changeRCtoken(rcval);
  };

  if (user) {
    return <Loading />;
  } else {
    return (
      <div className="login-container">
        <main className="form-signin">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <img className="mb-4" src="/logo.png" alt="" height="100" />
            <h1 className="h3 mb-3 fw-normal">Admin Login</h1>

            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="fUsername"
                placeholder="Username"
                maxLength="50"
                {...register("username", { required: true, maxLength: 50 })}
              />
              <label htmlFor="fUsername">Username</label>
              {errorMessage(errors.username)}
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="fPassword"
                placeholder="Password"
                maxLength="50"
                {...register("password", { required: true, maxLength: 50 })}
              />
              <label htmlFor="fPassword">Password</label>
              {errorMessage(errors.password)}
            </div>

            <div className="form-floating">
              <ReCAPTCHA
                ref={rcref}
                sitekey={config.rcSiteKey}
                hl="en"
                onChange={rcchange}
              />
            </div>

            {/* <div className="checkbox mb-3">
              <label>
                <input type="checkbox" value="remember-me" /> Remember me
              </label>
            </div> */}
            <button
              disabled={isSubmitting}
              className="w-100 mt-2 btn btn-lg btn-primary"
              type="submit"
            >
              {waitButton(isSubmitting)}
              {!isSubmitting && "Sign in"}
            </button>

            {/* <TempNav /> */}
          </form>
        </main>
      </div>
    );
  }
};

export default Login;
