import React from "react";
import { NavLink } from "react-router-dom";

import "../../styles/shared/sidebar.css"

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="sidebar-container">
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{ width: "250px" }}>
          <a href="/admin/category" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <span className="fs-4">Admin Panel</span>
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto ">
            <li>

              <NavLink to="/admin/category" className="nav-link text-white">
                <i className="bi bi-collection"></i> <span className="ms-2"> Category</span>

              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/game" className="nav-link text-white">
                <i className="bi bi-controller"></i> <span className="ms-2"> Game</span>
              </NavLink>
            </li>
            <li>
            <NavLink to="/admin/score" className="nav-link text-white">
            <i className="bi bi-dpad"></i> <span className="ms-2"> Score</span>
              </NavLink>
            </li>

          </ul>

        </div></div>
    );
  }
}

export default Sidebar;
