import axios from "axios";
import React, { createContext, useState } from "react";

export const CurrentPlayerContext = createContext();

const CurrentPlayerContextProvider = (props) => {

  const cp = JSON.parse(localStorage.getItem("currentPlayer"));
  const [player, setPlayer] = useState(cp);
  axios.defaults.withCredentials = true;

  const changeCurrentPlayer = (data) => {
    localStorage.setItem("currentPlayer", JSON.stringify(data));
    setPlayer(data);
  };
  return (
    <CurrentPlayerContext.Provider value={{ player, changeCurrentPlayer }}>
      {props.children}
    </CurrentPlayerContext.Provider>
  );
};

export default CurrentPlayerContextProvider;
