import React from 'react';

const DisabledFormElement = ({register}) => {
    return (
        <div>
             <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("disabled")}
              />
              <label className="form-check-label">Disable</label>
            </div>
        </div>
    );
}

export default DisabledFormElement;
