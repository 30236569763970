import React, { useContext } from "react";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = () => {
  return (
    <div className="d-flex flex-nowrap">
      <Sidebar />
      <div className="flex-fill">
        <Header />
        <div className="p-4">
          <Outlet />
        </div>
      </div>
      {/* {this.props.children} */}
    </div>
  );
};

export default Layout;
