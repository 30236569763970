import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import config from "../../../helpers/config";
import DisabledFormElement from "../../shared/DisabledFormElement";
import NameFormElement from "../../shared/NameFormElement";
import SaveButton from "../../shared/SaveButton";
import SubContainer from "../../shared/SubContainer";
import SubHeader from "../../shared/SubHeader";

const CategorySave = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: props.category });
  const onSubmit = (data) => {
    axios
      .post(config.callUrl + "/category/save", data)
      .then(() => {
        toast.success("Success");
        props.changePageMode("list");
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };
  if (errors) {
    console.error(errors);
  }
  return (
    <SubContainer>
      <SubHeader
        title="Save Category"
        linkTitle="Category List"
        willChangePageMode="list"
        changePageMode={props.changePageMode}
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3">
          <div className="col-6">
            <NameFormElement
              register={register}
              errors={errors}
              maxLength="250"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <DisabledFormElement register={register} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <SaveButton isSubmitting={isSubmitting}/>
          </div>
        </div>
      </form>
    </SubContainer>
  );
};

export default CategorySave;
