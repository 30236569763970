import React, { useState, useEffect } from "react";
import { categoryEnum } from "../../../helpers/enums";
import GameSave from "./GameSave";
import GameList from "./GameList";
import PageTitle from "../../shared/PageTitle";
import RequireAuth from "../../shared/RequireAuth";

const Game = () => {
  const [pageMode, setPageMode] = useState("list");
  const [selectedGame, setSelectedGame] = useState(null);
  const [enums, setEnums] = useState(null);

  const changePageMode = (mode) => {
    setSelectedGame(null);
    setPageMode(mode);
  };

  const selectGame = (game) => {
    setSelectedGame(game);
    setPageMode("save");
  };

  useEffect(() => {
    categoryEnum((data) => {
      setEnums({ categoryEnum: data });
    });
  }, []);

  return (
    <RequireAuth validate="/game">
      <div>
        <PageTitle title="Game" />

        {pageMode === "save" && (
          <GameSave
            changePageMode={changePageMode}
            game={selectedGame}
            enums={enums}
          />
        )}
        {pageMode === "list" && (
          <GameList
            changePageMode={changePageMode}
            selectGame={selectGame}
            enums={enums}
          />
        )}
      </div>
    </RequireAuth>
  );
};

export default Game;
