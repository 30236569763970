import React, { useState } from "react";
import CategoryList from "./CategoryList";
import CategorySave from "./CategorySave";
import PageTitle from "../../shared/PageTitle";
import RequireAuth from "../../shared/RequireAuth";

const Category = () => {
  const [pageMode, setPageMode] = useState("list");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const changePageMode = (mode) => {
    setSelectedCategory(null);
    setPageMode(mode);
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);
    setPageMode("save");
  };
  return (
    <RequireAuth validate="/category">
      <div>
        <PageTitle title="Category" />
        {pageMode === "save" && (
          <CategorySave
            changePageMode={changePageMode}
            category={selectedCategory}
          />
        )}
        {pageMode === "list" && (
          <CategoryList
            changePageMode={changePageMode}
            selectCategory={selectCategory}
          />
        )}
      </div>
    </RequireAuth>
  );
};

export default Category;
