import { toast } from "react-toastify";

const { default: axios } = require("axios");
const config = require("./config");

export const categoryEnum = (cb) => {
  console.log("categoryEnum çekiliyor");
  axios
    .get(config.callUrl + "/category/get")
    .then((response) => {
      cb(response.data);
    })
    .catch((error) => {
      console.error(error);
      toast.error(error.response.data);
    });
};

export const getGameList = (c, cb) => {
  console.log("gameList çekiliyor");
  axios
    .get(config.callUrl + "/game/games?c=" + c)
    .then((response) => {
      cb(response.data);
    })
    .catch((error) => {
      console.error(error);
      toast.error(error.response.data);
    });
};

export const getEnumName = (list, id) => {
  return list?.find((a) => {
    return a._id === id;
  })?.name;
};
