const PageTitle = (props) => {
    return (

        <div className="alert alert-info callout-border border-default" role="alert">
            
                <h5 className="m-0">{props.title}</h5>
            
        </div>
    );
}
export default PageTitle;