import axios from "axios";
import React, { useState, useEffect } from "react";
import config from "../../../helpers/config";
import DisableCheckbox from "../../shared/DisableCheckbox";
import EditDeleteButton from "../../shared/EditDeleteButton";
import Pager from "../../shared/Pager";
import SubContainer from "../../shared/SubContainer";
import SubHeader from "../../shared/SubHeader";
import { toast } from "react-toastify";

const Categorylist = (props) => {
  const [categoryList, setCategoryList] = useState();

  useEffect(() => {
    axios
      .get(config.callUrl + "/category/get")
      .then((response) => {
        // toast.success("Success");
        setCategoryList(response.data?.reverse());
      })
      .catch((error) => {
        toast.error(error.response.data);
        console.error(error);
      });
  }, []);

  const onDisabledChanged = (id, disabled) => {
    axios
      .put(config.callUrl + "/category/changedisabled/" + id, { d: disabled })
      .then((response) => {
        toast.success("Success");

        let list = [...categoryList];

        list.forEach((i) => {
          if (i._id === id) {
            i.disabled = disabled;
            return false;
          }
        });

        setCategoryList(list?.reverse());
      })
      .catch((error) => {
        toast.error(error.response.data);
        console.error(error);
      });
  };

  const onClickEditDelete = (type, id) => {
    if (type === "edit") {
      const category = categoryList.find((a) => {
        return a._id === id;
      });
      props.selectCategory(category);
    } else if (type === "del") {
      axios
        .put(config.callUrl + "/category/delete/" + id)
        .then((response) => {
          toast.success("Success");

          const list = categoryList.filter((i) => {
            return i._id !== id;
          });

          setCategoryList(list?.reverse());
        })
        .catch((error) => {
          toast.error(error.response.data);

          console.error(error);
        });
    }
  };

  return (
    <SubContainer>
      <SubHeader
        title="Category List"
        linkTitle="New Category"
        willChangePageMode="save"
        changePageMode={props.changePageMode}
      />
      <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Disabled</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {categoryList?.map((item) => {
            return (
              <tr key={item._id}>
                <td>{item._id}</td>
                <td>{item.name}</td>
                <td>
                  <DisableCheckbox
                    checked={item.disabled}
                    id={item._id}
                    onChange={onDisabledChanged}
                  />
                </td>
                <td className="text-end">
                  <EditDeleteButton id={item._id} onClick={onClickEditDelete} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pager />
    </SubContainer>
  );
};

export default Categorylist;
