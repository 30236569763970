import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CurrentPlayerContext } from "../../../contexts/CurrentPlayerContext";
import config from "../../../helpers/config";

export default function Certs() {
  const { player, changeCurrentPlayer } = useContext(CurrentPlayerContext);
  const [certs, setCerts] = useState([]);

  useEffect(() => {
    if (player?._id) {
      axios
        .get(config.callUrl + "/player/getPlayerCerts?pid=" + player._id)
        .then((data) => {
          setCerts(data?.data);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.response.data);
        });
    }
  }, [player]);

  const downloadCert = (pgid) => {
    axios({
      url: config.callUrl + "/player/downloadCert?pgid=" + pgid,
      method: "GET",
      responseType: "blob", // important
    })
      // axios
      //   .get(config.callUrl + "/player/downloadCert?pgid=" + pgid)
      .then((response) => {
        console.log(response);
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "sertifika.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Sertifikalar</h5>
        <hr />
        <div className="card-text">
          <ul>
            {Array.isArray(certs)
              ? certs?.map((item) => {
                  return (
                    <li key={item._id}>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          downloadCert(item._id);
                        }}
                      >
                        {item.game.category.name} {item.game.name}
                      </a>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
