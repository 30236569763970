import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import axios from "axios";
import config from "../../helpers/config";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { CurrentPlayerContext } from "../../contexts/CurrentPlayerContext";

const PlayerRequireAuth = ({ validate, children }) => {
  const navigate = useNavigate();
  const { player, changeCurrentPlayer } = useContext(CurrentPlayerContext);

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    console.log("require auth player [] effect");
    axios
      .get(config.callUrl + validate)
      .then(() => {
        console.log("require auth true");
        setAuthorized(true);
      })
      .catch((error) => {
        console.log("require auth false");
        console.error(error);
        toast.error(error.response.data);
        changeCurrentPlayer(null);
        navigate("/player/weblogin?nr=1");
      });
  }, []);

  if (authorized) {
    return children;
  } else {
    return <Loading />;
  }
};

export default PlayerRequireAuth;
