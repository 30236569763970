import React from "react";

export default function Home() {
  return (
    <div
      style={{
        textAlign: "center",
        backgroundImage: "url('/bg.jpg')",
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src="/logo.png" style={{ width: "500px", marginTop: "100px" }}></img>
    </div>
  );
}
