import React from "react";
import { errorMessage } from "../../helpers/form-helper";

const NameFormElement = ({ register, errors, maxLength }) => {
  return (
    <div>
      <label className="form-label" htmlFor="name">
        Name
      </label>
      <input
        type="text"
        className="form-control"
        maxLength={maxLength}
        {...register("name", { required: true, maxLength })}
      />
      {errorMessage(errors.name)}
    </div>
  );
};

export default NameFormElement;
