import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const CurrentUserContext = createContext();

const CurrentUserContextProvider = (props) => {
  const cu = JSON.parse(localStorage.getItem("currentUser"));
  // console.log("currentUser: "+cu);
  const [user, setUser] = useState(cu);
  axios.defaults.withCredentials = true;

  if (user) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + user._id;
  }

  // useEffect(() => {
  //  console.log("current user context [] effect");
  // }, [])
  

  const changeCurrentUser = (data) => {
    localStorage.setItem("currentUser", JSON.stringify(data));
    if (data) {
      console.log(JSON.stringify(data));
      axios.defaults.headers.common["Authorization"] = "Bearer " + data?._id;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
    setUser(data);
  };
  return (
    <CurrentUserContext.Provider value={{ user, changeCurrentUser }}>
      {props.children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserContextProvider;
