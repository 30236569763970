import React, { useContext } from "react";
import axios from "axios";
import "../../../styles/pages/login.css";
import config from "../../../helpers/config";
import { toast } from "react-toastify";
import WebSignupForm from "./WebSignupForm";
import { CurrentPlayerContext } from "../../../contexts/CurrentPlayerContext";
import { useNavigate } from "react-router-dom";

export default function WebSignup() {
  const { player, changeCurrentPlayer } = useContext(CurrentPlayerContext);
  const nav = useNavigate();

  const savePlayer = (rctoken, data, resetCaptcha) => {
    axios
      .post(config.callUrl + "/playerweb/signupwc?rctoken=" + rctoken, data)
      .then((response) => {

        toast.success("Kayıt başarılı. İsterseniz bu sayfadan bilgilerinizi güncelleyebilirsiniz." );
        changeCurrentPlayer(response.data);
        nav("/player/account");


        // toast.success(
        //   "Kayıt başarılı. Oyuna yönlendiriliyorsunuz. Lütfen bekleyiniz..."
        // );
        // console.log(response);
        // setTimeout(() => {
        //   window.location.href = config.gameUrl + "?p=" + response.data;
        // }, 2000);
      })
      .catch((error) => {
        resetCaptcha();
        console.error(error);
        toast.error(error?.response?.data);
      });
  };

  return <WebSignupForm savePlayer={savePlayer} />;
}
