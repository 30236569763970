import React, { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import config from "../../../helpers/config";
import { errorMessage, waitButton } from "../../../helpers/form-helper";

export default function WebSignupForm({ savePlayer }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({});

  const rcref = createRef();
  const [rctoken, changeRCtoken] = useState("");

  const onSubmit = (data) => {
    if (!rctoken) {
      return;
    }
    savePlayer(rctoken, data,resetCaptcha);
  };

  const rcchange = (rcval) => {
    console.log("rcval: " + rcval);
    changeRCtoken(rcval);
  };

 const resetCaptcha=()=>{
  rcchange(null);
  window.grecaptcha.reset();
 }

  return (
    <div className="login-container">
      <main className="form-signin">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <img className="mb-4" src="/logo.png" alt="" height="100" />
          <h1 className="h3 mb-3 fw-normal">Üyelik Bilgileriniz</h1>

          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="fusername"
              placeholder="Kullanıcı Adı"
              maxLength="50"
              {...register("username", {
                required: true,
                maxLength: 50,
              })}
            />
            <label htmlFor="fusername">Kullanıcı Adı</label>
            {errorMessage(
              errors.username,
              "required",
              "Kullanıcı Adı zorunludur"
            )}
          </div>

          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="femail"
              placeholder="e-Posta"
              maxLength="50"
              {...register("email", {
                required: true,
                maxLength: 255,
                pattern: /^\S+@\S+$/i,
              })}
            />
            <label htmlFor="femail">e-Posta</label>
            {errorMessage(errors.email, "required", "e-Posta zorunludur")}
            {errorMessage(errors.email, "pattern", "e-Posta formatı hatalı")}
          </div>

          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="fname"
              placeholder="Ad"
              maxLength="250"
              {...register("name", {
                required: true,
                maxLength: 250,
              })}
            />
            <label htmlFor="fname">Ad</label>
            {errorMessage(errors.name, "required", "Ad zorunludur")}
          </div>

          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="fsurname"
              placeholder="Soyad"
              maxLength="250"
              {...register("surname", {
                required: true,
                maxLength: 250,
              })}
            />
            <label htmlFor="fsurname">Soyad</label>
            {errorMessage(errors.surname, "required", "Soyad zorunludur")}
          </div>

          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="fpassword"
              placeholder="Şifre"
              maxLength="50"
              {...register("password", { required: true, maxLength: 50 })}
            />
            <label htmlFor="fpassword">Şifre</label>
            {errorMessage(errors.password, "required", "Şifre zorunludur")}
          </div>

          <div className="form-floating">
            <ReCAPTCHA
              ref={rcref}
              sitekey={config.rcSiteKey}
              hl="tr"
              onChange={rcchange}
            />
          </div>

          <button
            disabled={isSubmitting}
            className="w-100 btn btn-lg btn-primary"
            type="submit"
          >
            {waitButton(isSubmitting)}
            {!isSubmitting && "Kayıt Ol"}
          </button>
        </form>
      </main>
    </div>
  );
}
