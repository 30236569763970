import axios from "axios";
import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CurrentPlayerContext } from "../../contexts/CurrentPlayerContext";
import config from "../../helpers/config";

const PlayerHeader = () => {
  const { player, changeCurrentPlayer } = useContext(CurrentPlayerContext);

  const navigate = useNavigate();

  const logout = () => {
    axios
      .get(config.callUrl + "/playerweb/logout")
      .then(() => {
        changeCurrentPlayer(null);
        navigate("/player/weblogin?nr=1");
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-3 py-2">
      <a className="navbar-brand" href="#">
        Kullanıcı Yönetim Paneli
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#nt1"
        aria-controls="nt1"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="nt1">
        <ul className="navbar-nav">
          <li className="nav-item ">
            <NavLink to="/player/account" className="nav-link text-white">
              <i className="bi bi-collection"></i>{" "}
              <span className="ms-2"> Hesap Ayarları</span>
            </NavLink>
          </li>
        </ul>

        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="dd1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-person-circle me-2"></i>{" "}
              <span className="fs-6 fw-light">{player?.name}</span>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end dropdown-menu-dark"
              aria-labelledby="dd1"
            >
              <li>
                <a className="dropdown-item" onClick={logout} href="#">
                  Çıkış
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default PlayerHeader;
