import React, { createRef, useState } from "react";
import axios from "axios";
// import TempNav from "../../shared/TempNav";
import "../../../styles/pages/login.css";
import { useForm } from "react-hook-form";
import config from "../../../helpers/config";
import { errorMessage, waitButton } from "../../../helpers/form-helper";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

export default function WebResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({});
  const rcref = createRef();
  const [rctoken, changeRCtoken] = useState("");
  const nav = useNavigate();

  const onSubmit = (data) => {
    if (!rctoken) {
      return;
    }
    axios
      .post(
        config.callUrl + "/playerweb/resetpassword?rctoken=" + rctoken,
        data
      )
      .then((response) => {
        console.log(response);

        toast.success(response.data);
        nav("/player/weblogin");
      })
      .catch((error) => {
        rcchange(null);
        window.grecaptcha.reset();
        console.error(error);
        toast.error(error?.response?.data);
      });
  };
  // if (errors) {
  //   console.error(errors);
  // }

  const rcchange = (rcval) => {
    console.log("rcval: " + rcval);
    changeRCtoken(rcval);
  };

  return (
    <div className="login-container">
      <main className="form-signin">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <img className="mb-4" src="/logo.png" alt="" height="100" />
          <h1 className="h3 mb-3 fw-normal">Şifremi Unuttum</h1>

          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="id_email"
              placeholder="e-Posta"
              maxLength="50"
              {...register("email", {
                required: true,
                maxLength: 255,
                pattern: /^\S+@\S+$/i,
              })}
            />
            <label htmlFor="id_email">e-Posta</label>
            {errorMessage(errors.email, "required", "e-Posta zorunludur")}
            {errorMessage(errors.email, "pattern", "e-Posta formatı hatalı")}
          </div>

          <div className="form-floating">
            <ReCAPTCHA
              ref={rcref}
              sitekey={config.rcSiteKey}
              hl="tr"
              onChange={rcchange}
            />
          </div>

          <button
            disabled={isSubmitting}
            className="w-100 btn btn-lg btn-primary"
            type="submit"
          >
            {waitButton(isSubmitting)}
            {!isSubmitting && "Şifre sıfırlama isteği gönder"}
          </button>
        </form>
      </main>
    </div>
  );
}
