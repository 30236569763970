const config = {
  callUrl: "https://kelimevarapi.ingilizce.fun",
  // callUrl: "http://localhost:3002", //test

  gameUrl: "https://ingilizce.fun/game-screen",
  // gameUrl: "http://lus", //test

  rcSiteKey:"6LfNQj8iAAAAAHG5EebBh6mNEjqkORuDykTvADFS",
  // rcSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI", //test
};

console.log(config.callUrl);
module.exports = config;
