import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import config from "../../../helpers/config";
import { categoryEnum, getEnumName, getGameList } from "../../../helpers/enums";
import CategorySelect from "../../shared/CategorySelect";
import DisableCheckbox from "../../shared/DisableCheckbox";
import EditDeleteButton from "../../shared/EditDeleteButton";
import Pager from "../../shared/Pager";
import SubContainer from "../../shared/SubContainer";
import SubHeader from "../../shared/SubHeader";
import CertModal from "./CertModal";

const ScoreList = (props) => {
  const [categoryList, setCategoryList] = useState();
  const [gameList, setGameList] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [scores, setScores] = useState([]);
  const [pager, setPager] = useState({ page: 1, size: 20 });
  const [selectedPlayerGame, setSelectedPlayerGame] = useState();

  useEffect(() => {
    categoryEnum(setCategoryList);
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      getGameList(selectedCategory, setGameList);
    }
    setScores([]);
  }, [selectedCategory]);

  useEffect(() => {
    getScores();
  }, [selectedGame, pager]);

  const getScores = () => {
    if (selectedGame) {
      axios
        .get(
          config.callUrl +
            "/game/scores?g=" +
            selectedGame +
            "&page=" +
            pager.page +
            "&size=" +
            pager.size
        )
        .then((response) => {
          setScores(response.data);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.response.data);
        });
    } else {
      setScores([]);
    }
  };

  const categoryChanged = (e) => {
    setSelectedCategory(e.target.value);
  };
  const gameChanged = (e) => {
    setSelectedGame(e.target.value);
  };

  const pagerChanged = (p) => {
    setPager(p);
    // getScores();
  };

  const certClicked = (e, data) => {
    setSelectedPlayerGame(data);
  };

  return (
    <SubContainer>
      <SubHeader title="Score List" />

      <div className="row mb-4">
        <div className="col-md-6">
          <label className="mb-2">Category</label>
          <select className="form-select" onChange={categoryChanged}>
            <option value=""></option>
            {categoryList?.map((c) => {
              return (
                <option key={c._id} value={c._id}>
                  {c.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <label className="mb-2">Game</label>

          <select className="form-select" onChange={gameChanged}>
            <option value=""></option>
            {gameList?.map((c) => {
              return (
                <option key={c._id} value={c._id}>
                  {c.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-2"></div>
      </div>
      {selectedGame && (
        <div className="alert alert-danger">Total players: {scores?.count}</div>
      )}

      <table className="table">
        <thead>
          <tr>
            {/* <th>Id</th> */}
            <th>Name</th>
            <th>Surname</th>
            <th>Time</th>
            <th>Score</th>
            <th>Mail</th>
            <th>Class</th>
            <th>School</th>
            <th>Town</th>
            <th>City</th>
            <th>Phone</th>
            <th>Certificate</th>
          </tr>
        </thead>
        <tbody>
          {scores?.scores?.map((item) => {
            return (
              <tr key={item._id}>
                {/* <td>{item._id}</td> */}
                <td data-id={item._id}>{item.player.name}</td>
                <td>{item.player.surname}</td>
                <td>{item.totalTime}</td>
                <td>{item.score}</td>
                <td>{item.player.email}</td>
                <td>{item.player.class}</td>
                <td>{item.player.school}</td>
                <td>{item.player.town}</td>
                <td>{item.player.city}</td>
                <td>{item.player.phone}</td>

                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#certModal"
                    onClick={(e) => {
                      certClicked(e, item);
                    }}
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pager count={scores.count} changed={pagerChanged} />

      <CertModal pg={selectedPlayerGame} />
    </SubContainer>
  );
};

export default ScoreList;
