function DisableCheckbox(props) {
  const onChange = (e) => {
    e.preventDefault();
    props.onChange(props.id, e.target.checked);
  };

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        name="disable"
        checked={props.checked}
        onChange={(e) => {
          onChange(e);
        }}
      />
    </div>
  );
}

export default DisableCheckbox;
